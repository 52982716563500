export default {
  development: {
    API_URL: "https://api.v2.noly-compta.fr:3500",
    // API_URL: "http://localhost:4500",
    APPLICATION_ID: process.env.REACT_APP_APPLICATION_ID,
    BaseURL: "http://localhost:3000",
    APPLICATION_NAME: process.env.REACT_APP_APPLICATION_NAME,
    PRIMCOLOR: process.env.REACT_APP_PRIMCOLOR,
    SECCOLOR: process.env.REACT_APP_SECCOLOR,
    TERCOLOR: process.env.REACT_APP_TERCOLOR,
    LOGO: process.env.REACT_APP_LOGO,
  },
  production: {
    API_URL: "https://api.v2.noly-compta.fr:3500",
    APPLICATION_ID: process.env.REACT_APP_APPLICATION_ID,
    BaseURL: "https://prc.noly-compta.fr/",
    APPLICATION_NAME: process.env.REACT_APP_APPLICATION_NAME,
    PRIMCOLOR: process.env.REACT_APP_PRIMCOLOR,
    SECCOLOR: process.env.REACT_APP_SECCOLOR,
    TERCOLOR: process.env.REACT_APP_TERCOLOR,
    LOGO: process.env.REACT_APP_LOGO,
  },
  test: {
    API_URL: "https://api.v2.noly-compta.fr:3500",
    APPLICATION_ID: process.env.REACT_APP_APPLICATION_ID,
    BaseURL: "https://prc.noly-compta.fr/",
    APPLICATION_NAME: process.env.REACT_APP_APPLICATION_NAME,
    PRIMCOLOR: process.env.REACT_APP_PRIMCOLOR,
    SECCOLOR: process.env.REACT_APP_SECCOLOR,
    TERCOLOR: process.env.REACT_APP_TERCOLOR,
    LOGO: process.env.REACT_APP_LOGO,
  },
};
