import React, {useState, FormEvent, useEffect} from "react";

import "./forgotPassword.scss";
import {
  Alert,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import imgLogin from "../../../assets/images/img-auth.png";
import loginImg from "../../../assets/images/logo.svg";
import keyImg from "../../../assets/images/svg/key.svg";
import QrCodeImg from "../../../assets/images/svg/qr-code.svg";
import {Link, useNavigate, useParams} from "react-router-dom";
import Tabs from "../../../components/Tabs";
import {tabs} from "../index";
import {SubmitHandler, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import config from "../../../config";
import {ErrorLogger} from "../../../util/errorLogger";
import useAxios from "../../../util/hooks/useAxios";
import {images} from "../../../constants";
import {ReactSVG} from "react-svg";

type FormValues = {
  password: string;
  password_repeat: string;
};

const validateSchema = yup.object().shape({
  password: yup.string().required("Le mot de passe est un champ obligatoire"),
  password_repeat: yup.string().required("Un deuxième mot de passe est requis"),
});

const {API_URL} = config[process.env.NODE_ENV];

const ForgotPassword: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<FormValues>({
    resolver: yupResolver(validateSchema),
  });

  let navigate = useNavigate();
  const {token} = useParams();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const {ref: passwordRef, ...password} = register("password");
  const {ref: passwordRepeatRef, ...password_repeat} =
    register("password_repeat");
  let api = useAxios();

  const onSubmit: SubmitHandler<FormValues> = async (payload) => {
    try {
      if (
        payload.password.toLowerCase() !== payload.password_repeat.toLowerCase()
      ) {
        setErrorMessage("Les mots de passe ne correspondent pas");
        return;
      }
      setLoading(true);
      const {data} = await api.post(`/api/auth/resetPassword/${token}`, {
        password: payload.password,
      });
      if (data.message.toLowerCase() === "ok") {
        setSuccessMessage(
          "Mot de passe mis à jour, vous serez redirigé vers la page de connexion."
        );
      }
      setTimeout(() => {
        setLoading(false);
        navigate("/login");
      }, 3000);
    } catch (error: any) {
      setLoading(false);
      if (
        error.response.data.err
          .toLowerCase()
          .includes("password reset token is invalid or has expired.")
      ) {
        setErrorMessage(
          "Tentative invalide de réinitialisation du mot de passe, veuillez vous connecter."
        );
      }
      ErrorLogger("reset password form", error);
    }
  };

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const {data} = await api.post(`/api/auth/verifyResetToken`, {
          token,
        });

        if (data.valid.toLowerCase() === "nok") {
          return setErrorMessage(
            "Tentative invalide de réinitialisation du mot de passe, veuillez vous connecter."
          );
        }
      } catch (error: any) {
        ErrorLogger("state rehydration in App", error);
      }
    };

    verifyToken();

    // return () => {
    //   console.log("unmount");
    // };
  }, []);

  return (
    <div className="main-auth">
      <div className="container-auth">
        <Row className="row-auth">
          <Col md={4} className="leftLogin">
            <div className="innerLeftLogin">
              <div className="img-login">
                <img src={imgLogin} alt="img" />
              </div>
            </div>
          </Col>

          <Col md={8} className="righttLogin">
            <div className="innerrightLogin">
              <div className="main-signin">
                <div className="top-Login">
                  <div className="login-top">
                    <img src={images.logo} />
                  </div>
                  <h1>Récupérer mon mot de passe</h1>
                  <p>
                    Entrez votre email et votre mot de passe pour accéder à
                    votre compte PRC
                  </p>
                </div>
                <form
                  className="connexion-form"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  {/* <FormGroup className="form-icon icon-start">
                    <Input
                      id="code"
                      name="code"
                      placeholder="Code de vérification"
                      type="password"
                      className="form-secondary"
                    />

                    <span className="icon icon-secondary icon-secondary-fill">
                      <img src={QrCodeImg} alt="icon" />
                    </button>
                  </FormGroup> */}
                  <FormGroup className="form-icon icon-start">
                    <Input
                      id="pwd"
                      placeholder="Mot de passe"
                      type="password"
                      className="form-secondary"
                      {...password}
                      onChange={() => {
                        setErrorMessage(null);
                      }}
                      innerRef={passwordRef}
                    />

                    <span className="icon icon-secondary icon-secondary-fill">
                      {/* <img src={keyImg} alt="icon" /> */}
                      {<ReactSVG src={keyImg} />}
                    </span>
                  </FormGroup>
                  {errors.password && (
                    <Alert color="danger">{errors.password?.message}</Alert>
                  )}
                  <FormGroup className="form-icon icon-start">
                    <Label for="pwd">Confirmation Mot de passe</Label>
                    <Input
                      id="pwd"
                      placeholder="Confirmez votre mot de passe"
                      type="password"
                      className="form-secondary"
                      {...password_repeat}
                      onChange={() => {
                        setErrorMessage(null);
                      }}
                      innerRef={passwordRepeatRef}
                    />

                    <span className="icon icon-secondary icon-secondary-fill">
                      {/* <img src={keyImg} alt="icon" /> */}
                      {<ReactSVG src={keyImg} />}
                    </span>
                  </FormGroup>
                  {errors.password_repeat && (
                    <Alert color="danger">
                      {errors.password_repeat?.message}
                    </Alert>
                  )}
                  {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
                  {successMessage && (
                    <Alert color="info">{successMessage}</Alert>
                  )}
                  <div className="bottom-action">
                    <button
                      className="btn btn-secondary"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? (
                        <Spinner color="light" type="border" size={"sm"}>
                          Loading...
                        </Spinner>
                      ) : (
                        "Confirmer"
                      )}
                    </button>

                    <div className="bottom-link">
                      <Link className="forgot-pwd" to="/login">
                        Connexion
                      </Link>
                    </div>
                  </div>
                </form>
              </div>

              {/* <Tabs orientation="vertical" tabs={tabs} /> */}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ForgotPassword;
